<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="search-form">
                <el-form ref="searchform" :model="searchForm" label-width="80px" :inline="true">
                    <el-form-item>
                        <label slot="label">账号：</label>
                        <el-input @change="searchInfo" v-model="searchForm.name" suffix-icon="el-icon-search" placeholder="请输入用户名">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <label slot="label">有效期：</label>
                        <el-select v-model="searchForm.status" @change="searchInfo">
                            <el-option v-for="(item,index) in termValidity" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <label slot="label"></label>
                        <el-button type="primary" @click="delallInfo">批量删除</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="info-table">
                <info-table ref="accountTable" :tableHeadConfig="tableHeadConfig" :tableLoading="tableLoading"
                    :tableLoadData="tableLoadData" align="center" @selectLine="handleSelectionChange">
                    <template v-slot:name="slotData">
                        <div>
                            {{slotData.data.row.name}}
                        </div>
                        <div>
                            {{slotData.data.row.phone}}
                        </div>
                    </template>
                    <template v-slot:app_num="slotData">
                        <div>
                            {{canCreate(slotData.data.row.app_num)}}
                        </div>
                    </template>
                    <template v-slot:time="slotData">
                        <div class="last-status">
                            <div
                                :class="[{'expire-icon':slotData.data.row.last_status==='未到期'},{'expired-icon':slotData.data.row.last_status==='到期'}]">
                                {{slotData.data.row.last_status}}
                            </div>
                            <div class="cross-center">
                                {{calValidity(slotData.data.row.time)}}
                            </div>
                        </div>
                    </template>
                    <template v-slot:operation="slotData">
                        <div class="table-oper" @click="editInfo(slotData.data.row)">
                            编辑
                        </div>
                        <div class="table-oper" @click="deleteInfo(slotData.data.row)">
                            删除账户
                        </div>
                    </template>
                </info-table>
            </div>

            <div class="info-page">
                <infoPage :pagination="pagination" @pageclcik="pageclcik"></infoPage>
            </div>

            <deldialog :appId="skuId" :dialogVal="delDialog" title="您确定要删除该账号吗?" @dialogChange="dialogChange">
            </deldialog>
            <deldialog :appId="skuId" :dialogVal="delAllDialog" title="您确定要删除这些账号吗?" @dialogChange="delMany">
            </deldialog>
        </div>
    </div>
</template>

<script>
    import {encrypt} from '@/utils/encry.js'
    export default {
        data() {
            return {
                delDialog: false,
                delAllDialog: false,
                skuId: [], //选中账户的id
                loading:false,
                // 表头
                tableHeadConfig: [
                    {
                        label: "账号",
                        field: "account",
                    },
                    {
                        label: "用户名",
                        field: "name",
                        columnType: "slot",
                        slotName: "name",
                    },
                    {
                        label: "可创建的小程序数量",
                        columnType: "slot",
                        slotName: "app_num",
                    },
                    {
                        label: "有效期",
                        columnType: "slot",
                        slotName: "time",
                        width:250
                    },
                    {
                        label: "创建日期",
                        field: "created_at",
                    },
                    {
                        label: "操作",
                        columnType: "slot",
                        slotName: "operation",
                        align: "center"
                    },
                ],
                tableLoading: false, // 是否显示加载
                // 表格内容
                tableLoadData: [],
                // 有效期选择框
                termValidity: [{
                        value: '0',
                        label: '全部',
                    },
                    {
                        value: '1',
                        label: '未到期',
                    },
                    {
                        value: '2',
                        label: '已到期',
                    },
                ],
                searchForm: {
                    name: '',
                    status: '0'
                },
                pagination: {
                    currentpage: 1,
                    total: 1,
                    totalpage: 1,
                    pageSize: 8
                },
            }
        },
        mounted() {
            this.getUsers()
        },
        computed: {
            canCreate() {
                return function (num) {
                    switch (num) {
                        case -1:
                            return '无限制'
                        case 0:
                            return '不可创建'
                        default:
                            return num
                    }
                }
            },
            calValidity(){
                return function(time){
                    if(time==='0000-00-00 00:00:00'){
                        return '已到期'
                    }else{
                        return time
                    }
                }
            }
        },
        methods: {
            searchInfo(){
                this.pagination.currentpage =1
                this.getUsers()
            },
            getUsers() {
                const that = this
                that.tableLoading = true
                that.$request({
                    url: that.$api.merchantMent.getusers,
                    method: 'post',
                    data: {
                        page: this.pagination.currentpage,
                        name: this.searchForm.name,
                        status: this.searchForm.status
                    }
                }).then(res => {
                    that.tableLoading = false
                    that.pagination.currentpage = res.data.current_page
                    that.pagination.total = res.data.total
                    that.pagination.totalpage = res.data.last_page
                    that.pagination.pageSize = res.data.per_page
                    that.tableLoadData = res.data.data
                })
            },
            batchDelete() {
                console.log(this.$refs.accountTable, 'this.$refs.accountTable');
            },
            // 编辑账户
            editInfo(row) {
                const editId = encrypt(row.id)
                this.$router.push({
                    path: '/erchantMent/list/editlist',
                    query: {
                        editId
                    }
                })
            },
            // 删除账户
            deleteInfo(row) {
                this.skuId = []
                this.skuId.push(row.id)
                this.delDialog = true
            },
            dialogChange(type, id) {
                // 1：删除的确认 2：删除的取消
                const that = this
                console.log(type, id, 'type');
                if (type === 1) {
                    this.delDialog = false
                    that.$request({
                        url: that.$api.merchantMent.delUsers,
                        method: 'post',
                        data: {
                            ids: id
                        }
                    }).then(res => {
                        that.getUsers()
                    })
                } else if (type === 2) {
                    this.delDialog = false
                }
            },
            // 批量删除账户
            handleSelectionChange(selection){
                let arr = []
                selection.forEach(item => {
                    arr.push(item.id)
                });
                this.skuId = arr
            },
            delallInfo() {
                this.delAllDialog = true
            },
            delMany(type, id) {
                const that = this
                if(type == 1){
                    that.delAllDialog = false
                    that.$request({
                        url: that.$api.merchantMent.delUsers,
                        method: 'post',
                        data: {
                            ids: id
                        }
                    }).then(res => {
                        that.getUsers()
                    })
                }else {
                    that.delAllDialog = false
                }
            },
            // 分页器事件
            pageclcik(e) {
                this.pagination.currentpage = e
                this.getApp()
            },
        }
    }
</script>

<style lang="scss" scoped>
    .info-table {
        min-height: 450px;
        .table-oper {
            display: inline-block;
            color: #4458FE;
            cursor: pointer;

            &:before {
                content: "|";
                margin: 0 5px;
                color: #4458FE;
            }

            &:first-child:before {
                display: none;
            }
        }
    }
    .info-page {
        margin-top: 25px;
    }
    .last-status {
        display: flex;
        .expire-icon {
            background-color: #E5FFFC;
            padding: 5px 15px;
            color: #01D0B5;
            border: 1px solid #01D0B5;
            border-radius: 6px;
            margin-right: 15px;
        }

        .expired-icon {
            background-color: #FFE6E2;
            padding: 5px 10px;
            color: #FE725C;
            border: 1px solid #FE725C;
            border-radius: 6px;
            margin-right: 15px;
        }
    }
</style>